import React from "react";

export default function LGPrivacy() {
  return (
    <div style={{ width: "min(80%, 1000px)", paddingTop: "40px", margin: "0 auto" }}>
      Product Like Genius Privacy Policy
      <br></br>
      <br></br>
      Product Like Genius "the App” provides a theme extension to add informative bulletpoints on your productpage, "the
      Service", to merchants who use Shopify to power their stores. This Privacy Policy describes how personal
      information is collected, used, and shared when you install or use the App in connection with your
      Shopify-supported store.
      <br></br>
      <br></br>
      Personal Information the App Collects
      <br></br>
      <br></br>
      When you install the App, we are automatically able to access certain types of information from your Shopify
      account: your myshopify domain (www.example.myshopify.com) and all the producttypes and vendors that exist in the
      store. Those 3 informations are stored in our database together with the bulletpoints that are created. Nothing
      more.
      <br></br>
      <br></br>
      No cookies or tracking information is collected.
      <br></br>
      <br></br>
      How Do We Use Your Personal Information?
      <br></br>
      We use the personal information we collect from you and your customers in order to provide the Service and to
      operate the App.
      <br></br>
      <br></br>
      Sharing Your Personal Information
      <br></br>
      Your information is not shared with anybody.
      <br></br>
      <br></br>
      Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to
      a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
      <br></br>
      <br></br>
      Your Rights If you are a European resident, you have the right to access personal information we hold about you
      and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this
      right, please contact us through the contact information below.
      <br></br>
      <br></br>
      Additionally, if you are a European resident we note that we are processing your information in order to fulfill
      contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our
      legitimate business interests listed above. Additionally, please note that your information will be transferred
      outside of Europe, including to Canada and the United States.
      <br></br>
      <br></br>
      Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our
      practices or for other operational, legal or regulatory reasons.
      <br></br>
      <br></br>
      Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a
      complaint, please contact us by e-mail at felixmpaulus@googlemail.com or by mail using the details provided below:
    </div>
  );
}
