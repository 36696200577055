import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FelixPaulus from "./FelixPaulus";
import BBPrivacy from "./privacy/BBPrivacy";
import EEPrivacy from "./privacy/EEPrivacy";
import BEABPrivacy from "./privacy/BEABPrivacy";
import Projects from "./components/Projects";
import LGPrivacy from "./privacy/LGPrivacy";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/better-bullets/privacy">
          <BBPrivacy />
        </Route>
        <Route path="/easy-editor/privacy">
          <EEPrivacy />
        </Route>
        <Route path="/bamboo-easy-announcement-bar/privacy">
          <BEABPrivacy />
        </Route>
        <Route path="/product-like-genius/privacy">
          <LGPrivacy />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/">
          <FelixPaulus />
        </Route>
      </Switch>
    </Router>
  );
}
