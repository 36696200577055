import React from "react";
import "./App.css";
import { useState } from "react";

import mainImage from "./assets/images/Foto_Felix_Paulus_Quadrat_1.jpg";
import sunIcon from "./assets/images/sun_icon.png";
import { CONTACT } from "./constants/constants";

function FelixPaulus() {
  const [showImprint, setShowImprint] = useState(false);

  function Imprint() {
    return showImprint ? (
      <div className="imprint-modal">
        <span
          className="imprint-close-icon fa-regular fa-close fa-custom"
          onClick={() => {
            setShowImprint(false);
          }}
        ></span>
        <div className="imprint-content">
          <img className="imprint-sun-icon" src={sunIcon} alt="" />
          <br />
          <strong>Impressum</strong>
          <br />
          <br />
          Felix Paulus <br />
          Leipziger Str. 46 <br />
          10117 Berlin
        </div>
      </div>
    ) : null;
  }

  return (
    <div className="App">
      <Imprint />
      <div
        className="container"
        onClick={() => {
          setShowImprint(false);
        }}
      >
        <div className="left-column">
          <div className="main-image-container">
            <img className="main-image" src={mainImage} alt="Foto von Felix Paulus" />
            <div className="contact-information">
              {CONTACT.map(({ prefix, text, link }, i) => {
                return (
                  <div key={i} className="content-list-item-header">
                    {prefix}
                    <h3 className="h3-custom">
                      {link ? (
                        <a
                          className="content-list-item-text"
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                          download={text === "My CV" ? "CV_Felix_Paulus.pdf" : undefined}
                        >
                          {text}
                        </a>
                      ) : (
                        <div className="content-list-item-text">{text}</div>
                      )}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right-column">
          <h1 className="h1-custom">
            Hey! <br></br>I'm Felix Paulus,<br></br> a product manager.
          </h1>
          <p>
            Since a young age, I have been deeply intrigued and inspired by exceptional product design, particularly in
            the realm of software.
          </p>
          <p>
            With years of programming experience, both professionally and as a hobby, I now excel at bridging the gap
            between clients' ideas and developers' implementation, ensuring seamless product development.
          </p>
          <p>
            I am currently engaged with adesso SE, leading the design and development of an internal software solution
            focused on optimizing in-office work scheduling to improve collaboration in a home office dominated work
            culture.
          </p>
          <p>
            With a background in Computational Engineering Science and a Master's degree from Technical University
            Berlin, I have honed my skills in communication, leadership, and a profound understanding of programming and
            interface design.
          </p>
          <p>
            When not immersed in the world of technology, you'll find me swimming lanes in a pool or riding on my
            motorcycle.
          </p>
        </div>
      </div>
      <div className="empty-space"></div>
      <div className="legal-bar">
        <div
          className="imprint-link"
          onClick={() => {
            setShowImprint(true);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Impressum
        </div>
        <a className="jonas-link" href="https://www.jonasfriedrich.co/" target="_blank" rel="noreferrer">
          Photos by Jonas Friedrich
        </a>
      </div>
    </div>
  );
}

export default FelixPaulus;
